@import '@/scss/base/variables';
@import './listing/listing-items';

#content {
  .title-404 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-top: 10px;

    @media only screen and (max-width: $breakpoint-s) {
      margin-bottom: 20px;
    }

    h1 {
      font-size: 20px;
    }

    .bird {
      position: static;

      img {
        vertical-align: bottom;
      }
    }
  }

  .text-404 {
    margin-bottom: 100px;

    @media only screen and (max-width: $breakpoint-s) {
      margin-bottom: 40px;
    }
  }

  .pickup {
    h2 {
      float: none;
      font-size: 16px;
      margin-bottom: 16px;
    }
  }

  .banners {
    display: flex;
    gap: 10px;
    margin-top: 40px;

    @media only screen and (max-width: $breakpoint-s) {
      justify-content: space-between;
    }

    li {
      img {
        @media only screen and (max-width: $breakpoint-s) {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}
